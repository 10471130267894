import { createSlice } from "@reduxjs/toolkit";

// utils
import axios from "../../utils/axios";
import { useSnackbar } from "notistack";

// Slice to fetch User Detail

const initialState = {
  isLoading: true,
  isProfileUploading: false,
  isProfileUploadError: false,
  error: null,
  success: null,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.success = null;
      state.isLoading = true;
      state.error = false;
      state.user = {};
    },

    // Profile update Loading
    startProfileUpdateLoading(state) {
      state.success = null;
      state.isLoading = true;
      state.error = false;
    },

    // Forgot Pass Loading Start
    startForgotPassLoading(state) {
      state.success = null;
      state.isLoading = true;
      state.error = false;
    },

    // Upload Profile Picture Loading
    startUploadProfilePictureLoading(state) {
      state.success = null;
      state.isProfileUploading = true;
      state.isProfileUploadError = false;
    },

    // Password Reset Loading
    startPasswordResetLoading(state) {
      state.success = null;
      state.isLoading = true;
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.success = null;
      state.isLoading = false;
      state.error = action.payload;
      state.user = {};
    },

    // Profile Update has error
    profileUpdateHasError(state, action) {
      state.success = null;
      state.isLoading = false;
      state.error = action.payload;
    },

    // Password Reset Has Error
    passwordResetHasError(state, action) {
      state.success = null;
      state.isLoading = false;
      state.error = action.payload;
    },

    // Profile Picture Upload Error
    profilePictureUploadError(state, action) {
      state.success = null;
      state.isProfileUploading = false;
      state.isProfileUploadError = action.payload;
    },

    // Forgot Pass Has Error
    forgotPassHasError(state, action) {
      state.success = null;
      state.isLoading = false;
      state.error = action.payload;
    },

    // Profile Update Success
    profileUpdateSuccess(state, action) {
      state.success = true;
      state.isLoading = false;
      state.error = false;
      state.user = action.payload;
    },

    // Profile Picture Upload Success
    profilePictureUploadSuccess(state, action) {
      state.success = true;
      state.isProfileUploading = false;
      state.isProfileUploadError = false;
      state.user = action.payload;
    },

    // GET USER
    getUserSuccess(state, action) {
      state.success = true;
      state.isLoading = false;
      state.error = false;
      state.user = action.payload;
    },

    // Password Reset Success
    passwordResetSuccess(state, action) {
      state.success = true;
      state.isLoading = false;
      state.error = false;
    },

    // Forgot Pass Success
    forgotPassSuccess(state, action) {
      state.success = true;
      state.isLoading = false;
      state.error = false;
    },

    // get users success
    getAllUsersSuccess(state, action) {
      state.success = true;
      state.isLoading = false;
      state.error = false;
      state.success = action.payload;
    },

    startGetAllUsersLoading(state) {
      state.success = null;
      state.isLoading = true;
      state.error = false;
    },

    // Forgot Pass Has Error
    getAllUsersHasError(state, action) {
      state.success = null;
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { actions } = slice;

// Actions
export function getUser() {
  return async (dispatch) => {
    dispatch(actions.startLoading());
    try {
      const response = await axios.get("/user/profile");
      dispatch(actions.getUserSuccess(response.data?.data));
    } catch (error) {
      dispatch(actions.hasError(error));
    }
  };
}

export function updateProfile(data) {
  return async (dispatch) => {
    dispatch(actions.startProfileUpdateLoading());
    try {
      const response = await axios.put("user/profile-update", data);
      // dispatch(actions.profileUpdateSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(actions.profileUpdateHasError(error));
      return error;
    }
  };
}

export function forgotPassword(data) {
  return async (dispatch) => {
    dispatch(actions.startForgotPassLoading());
    try {
      // await axios.post("/api/v1/accounts/forgot-password/", data);
      // dispatch(actions.forgotPassSuccess());
    } catch (error) {
      // dispatch(actions.forgotPassHasError(error));
    }
  };
}

export function updatePassword(data) {
  return async (dispatch) => {
    dispatch(actions.startPasswordResetLoading());
    try {
      const response = await axios.post("update/update-password", data);
      console.log(response);

      dispatch(actions.passwordResetSuccess());
      return response;
    } catch (error) {
      console.log(error);

      dispatch(actions.passwordResetHasError(error.message));
      return error;
    }
  };
}

export function resetPassword(data) {
  return async (dispatch) => {
    dispatch(actions.startPasswordResetLoading());
    try {
      // await axios.post("/api/v1/accounts/reset-password/", data);
      // dispatch(actions.passwordResetSuccess());
    } catch (error) {
      dispatch(actions.passwordResetHasError(error));
    }
  };
}
