import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import { useSnackbar } from "notistack";
import { PATH_AUTH } from "src/routes/paths";

const useLogoutDialog = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });
      closeDialog(); // Closes the dialog
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  const renderDialog = () => (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        // Prevent closing on backdrop click or escape key press
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          closeDialog();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Log Out</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You have logged in on another device. Please log in again to continue.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleLogout} autoFocus>
          Log in again
        </Button>
      </DialogActions>
    </Dialog>
  );

  return {
    openDialog,
    renderDialog,
  };
};

export default useLogoutDialog;
